import axios from "redux/actions/instance";

// this uri is used by non logged in AUTH api forgot password, singup, login
const authUri = "/allauth/app/v1/auth/";
// this uri used for logged in user to update password, change email, etc...
const accountUri = "/allauth/app/v1/account/";
// social uri
export const socialUri = "allauth/app/v1/auth/";

export const forgotPasswordAPI = (payload: any) =>
  axios.post(`${authUri}password/request`, payload);

export const resetPasswordFromKeyAPI = (payload: any) =>
  axios.post(`${authUri}password/reset`, payload);

export const loginAPI = (payload: any) => {
  axios.defaults.headers.common.Authorization = "";
  return axios.post(`${authUri}login`, payload);
};

export const passwordChangeAPI = (payload: any) => {
  return axios.post(`${accountUri}password/change`, payload);
};

export const listEmailsAPI = () => {
  return axios.get(`${accountUri}email`);
};

export const addEmailAPI = (payload: any) => {
  return axios.post(`${accountUri}email`, payload);
};

export const sendEmailVerificationAPI = (payload: any) => {
  return axios.put(`${accountUri}email`, payload);
};

export const changePrimaryEmailAPI = (payload: any) => {
  return axios.patch(`${accountUri}email`, payload);
};

export const deleteEmailAPI = (payload: any) => {
  return axios.delete(`${accountUri}email`, { data: payload });
};

export const providerTokenAPI = (payload: any) => {
  return axios.post(`${socialUri}provider/token`, payload);
};

export const verifyEmailAPI = (payload: any) => {
  return axios.post(`${socialUri}email/verify`, payload);
};
