import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  apiErrorCatcher,
  setAuthTokens,
  setSessionToken,
} from "./common-action-utils";
import {
  addEmailAPI,
  changePrimaryEmailAPI,
  deleteEmailAPI,
  forgotPasswordAPI,
  listEmailsAPI,
  loginAPI,
  passwordChangeAPI,
  providerTokenAPI,
  resetPasswordFromKeyAPI,
  sendEmailVerificationAPI,
  verifyEmailAPI,
} from "redux/services/newAuth";
import { setUser } from "redux/actions";
import instance from "redux/actions/instance";

const initialState = {
  loading: false,
  formLoading: false,
  error: null,
  formSuccess: false,
  loginData: null,
  userEmails: [],
  listEmailLoading: false,
  deleteEmailLoading: false,
};

export const forgotPassword = createAsyncThunk(
  "api/forgotPassword",
  async (payload: any, thunkAPI) => {
    // payload contains {"email": "email@mail.com"}

    try {
      const response = await forgotPasswordAPI(payload);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const resetPasswordFromKey = createAsyncThunk(
  "api/resetPasswordFromKey",
  async (payload: any, thunkAPI) => {
    // payload contains {"key": "key", "password":"12321" }

    try {
      const response = await resetPasswordFromKeyAPI(payload);
      return response.data;
    } catch (error: any) {
      const response = error?.response || {};
      const { status, data } = response;
      if (status === 401) {
        // this is stil valid return a success data
        return data;
      }

      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const login = createAsyncThunk(
  "api/login",
  async (payload: any, thunkAPI) => {
    // this support username or email login
    // payload contains {"email": "key", "password":"12321" }
    // payload contains {"username": "key", "password":"12321" }

    try {
      const response = await loginAPI(payload);
      const mainData = response.data;
      const { data, meta } = mainData || {};
      const { user } = data || {};

      await setAuthTokens(meta.access, meta.refresh, meta.session_token);
      // at this point we also need to add the bearer token on header
      //@ts-ignore
      instance.defaults.headers.Authorization = `Bearer ${meta.access}`;
      instance.defaults.headers["X-Session-Token"] = meta.session_token;

      // if there are supervisor_code assign to localstorage
      if (payload.supervisor_code)
        localStorage.setItem("supervisor_code", payload.supervisor_code);
      // NOTE: this is to add the user this can still be optimize for later use.
      await thunkAPI.dispatch(setUser(user));

      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const passwordChange = createAsyncThunk(
  "api/passwordChange",
  async (payload: any, thunkAPI) => {
    // payload contains {"current_password" and "new_password"}
    try {
      const response = await passwordChangeAPI(payload);
      const mainData = response.data;
      const { meta } = mainData || {};

      setSessionToken(meta.session_token);
      instance.defaults.headers["X-Session-Token"] = meta.session_token;

      return response.data;
    } catch (error: any) {
      // apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const listEmails = createAsyncThunk(
  "api/listEmails",
  async (_, thunkAPI: any) => {
    try {
      const response = await listEmailsAPI();
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteEmail = createAsyncThunk(
  "api/deleteEmail",
  async (payload: any, thunkAPI) => {
    try {
      const response = await deleteEmailAPI(payload);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addEmail = createAsyncThunk(
  "api/addEmail",
  async (payload: any, thunkAPI) => {
    // payload contains {"email": "email@mail.com"}

    try {
      const response = await addEmailAPI(payload);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const sendEmailVerification = createAsyncThunk(
  "api/sendEmailVerification",
  async (payload: any, thunkAPI) => {
    // payload contains {"email": "email@mail.com"}

    try {
      const response = await sendEmailVerificationAPI(payload);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const providerToken = createAsyncThunk(
  "api/providerToken",
  async (payload: any, thunkAPI) => {
    try {
      const response = await providerTokenAPI(payload);
      const mainData = response.data;
      const { data, meta } = mainData || {};
      const { user } = data || {};

      setAuthTokens(meta.access, meta.refresh, meta.session_token);
      // at this point we also need to add the bearer token on header
      //@ts-ignore
      instance.defaults.headers.Authorization = `Bearer ${meta.access}`;
      instance.defaults.headers["X-Session-Token"] = meta.session_token;

      // NOTE: this is to add the user this can still be optimize for later use.
      await thunkAPI.dispatch(setUser(user));

      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "api/verifyEmail",
  async (payload: any, thunkAPI) => {
    // payload contains {"key": "testkeys"}

    try {
      const response = await verifyEmailAPI(payload);
      return response.data;
    } catch (error: any) {
      const response = error?.response || {};
      const { status, data } = response;
      if (status === 401) {
        // this is stil valid return a success data
        return data;
      }

      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const changePrimaryEmail = createAsyncThunk(
  "api/changePrimaryEmail",
  async (payload: any, thunkAPI) => {
    // payload contains {"email": "email@mail.com", "primary": true}

    try {
      const response = await changePrimaryEmailAPI(payload);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const newAuthSlice = createSlice({
  name: "newAuthSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.error = null;
        state.formSuccess = true;
        state.loginData = action.payload;
      })
      .addCase(login.rejected, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.error = action.payload;
        state.formSuccess = false;
      })

      .addCase(forgotPassword.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(
        forgotPassword.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = null;
          state.formSuccess = true;
        }
      )
      .addCase(forgotPassword.rejected, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.error = action.payload;
        state.formSuccess = false;
      })

      .addCase(resetPasswordFromKey.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(
        resetPasswordFromKey.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = null;
          state.formSuccess = true;
        }
      )
      .addCase(
        resetPasswordFromKey.rejected,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = action.payload;
          state.formSuccess = false;
        }
      )
      .addCase(passwordChange.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(
        passwordChange.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = null;
          state.formSuccess = true;
        }
      )
      .addCase(passwordChange.rejected, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.error = action.payload;
        state.formSuccess = false;
      })
      .addCase(listEmails.pending, (state) => {
        state.listEmailLoading = true;
      })
      .addCase(listEmails.fulfilled, (state, action: PayloadAction<any>) => {
        state.listEmailLoading = false;
        state.error = null;
        state.formSuccess = true;
        state.userEmails = action.payload.data;
      })
      .addCase(listEmails.rejected, (state, action: PayloadAction<any>) => {
        state.listEmailLoading = false;
        state.error = action.payload;
        state.formSuccess = false;
      })
      .addCase(deleteEmail.pending, (state) => {
        state.deleteEmailLoading = true;
      })
      .addCase(deleteEmail.fulfilled, (state, action: PayloadAction<any>) => {
        state.deleteEmailLoading = false;
        state.error = null;
        state.formSuccess = true;
        state.userEmails = action.payload.data;
      })
      .addCase(deleteEmail.rejected, (state, action: PayloadAction<any>) => {
        state.deleteEmailLoading = false;
        state.error = action.payload;
        state.formSuccess = false;
      })
      .addCase(providerToken.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(providerToken.fulfilled, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.error = null;
        state.formSuccess = true;
        state.loginData = action.payload;
      })
      .addCase(providerToken.rejected, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.error = action.payload;
        state.formSuccess = false;
      })
      .addCase(addEmail.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(addEmail.fulfilled, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.error = null;
        state.formSuccess = true;
        state.userEmails = action.payload.data;
      })
      .addCase(addEmail.rejected, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.error = action.payload;
        state.formSuccess = false;
      })
      .addCase(sendEmailVerification.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(
        sendEmailVerification.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = null;
        }
      )
      .addCase(
        sendEmailVerification.rejected,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = action.payload;
        }
      )
      .addCase(verifyEmail.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(verifyEmail.fulfilled, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.error = null;
        state.formSuccess = true;
      })
      .addCase(verifyEmail.rejected, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.error = action.payload;
        state.formSuccess = false;
      })
      .addCase(changePrimaryEmail.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(
        changePrimaryEmail.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = null;
          state.formSuccess = true;
          state.userEmails = action.payload.data;
        }
      )
      .addCase(
        changePrimaryEmail.rejected,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = action.payload;
          state.formSuccess = false;
        }
      );
  },
});

export default newAuthSlice.reducer;
