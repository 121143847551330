/* eslint-disable react-hooks/exhaustive-deps */
import { ExitToApp, Settings } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  Typography,
} from "@mui/material";
import UserSettingsModal from "components/common/components/logout/UserSettingsModal";
import { linkWithLanguage } from "components/helpers/commonLinks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import MuiMenu from "../mui_menu";
import useLogoutState from "./useLogoutState";
import { logoutButton } from "redux/slices/common-action-utils";
const Logout = () => {
  const history = useHistory();
  const [subscribtionStatus, setsubscribtionStatus] = useState();

  const {
    user_id,
    is_applicant,
    is_community_center,
    is_company,
    is_loyac_staff,
    is_lsv_usher,
    is_parent,
    is_thirdParty_supervisor,
    email,
  } = useSelector((state: any) => state.rootAuth.user);
  const rootAuth = useSelector((state: any) => state.rootAuth);
  const usherObj = useSelector((state: any) => state.rootUshers?.usherObj);
  const {
    loyacStaff,
    applicant,
    communitycenter,
    company,
    parent,
    supervisor,
  } = useSelector((state: any) => state.rootAuth);
  const [name, setname] = useState("");
  const [profileImage, setprofileImage] = useState("");
  const [profileType, setProfileType] = useState("");

  useEffect(() => {
    if (is_applicant || is_lsv_usher) {
      setProfileType("applicant");
      if (applicant) {
        setname(applicant.full_name);
        setprofileImage(applicant?.profile_image);
      } else if (is_lsv_usher) {
        setProfileType("ushers");
        setprofileImage(usherObj?.personal_image);
      }
    }
    if (is_community_center) {
      setProfileType("community_center");
      if (communitycenter) {
        setname(communitycenter.name);
        setprofileImage(communitycenter?.profile_image);
      }
    }
    if (is_company) {
      setProfileType("company");
      if (company) {
        setname(company.name);
        setprofileImage(company?.logo);
      }
    }
    if (is_loyac_staff) {
      setProfileType("staff");
      if (loyacStaff) {
        setname(loyacStaff.name);
        setprofileImage(loyacStaff?.profile_image);
      }
    }
    if (is_parent) {
      setProfileType("parent");
      if (parent) {
        setname(parent.name);
        setprofileImage(parent?.profile_image);
      }
    }
    if (is_thirdParty_supervisor) {
      setProfileType("supervisor");
      if (supervisor) {
        setname(supervisor.name);
        setprofileImage(supervisor?.profile_image);
      }
    }
  }, [rootAuth]);
  const handleProfile = () => {
    let id = user_id;
    if (profileType === "ushers") {
      id = usherObj?.usher_id;
    }
    history.push(linkWithLanguage(`/${profileType}/${id}/profile/`));
  };

  const handleAccountSettings = () => {
    history.push(linkWithLanguage(`/account/settings`));
  };

  const { settingsLoading, fetchUserEmailStatus, setSettingsLoading } =
    useLogoutState();
  const { t } = useTranslation();
  const [settingsModal, setSettingsModal] = useState(false);

  return (
    <>
      <MuiMenu
        title={
          <Avatar
            alt={name}
            src={profileImage}
            sx={{ width: 24, height: 24 }}
          />
        }
        isIconButton={true}
        leftShift={-15}
      >
        {(handleClose: Function) => (
          <div>
            <Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
                width={"100%"}
                px={3}
                component={"li"}
              >
                <Typography>{email}</Typography>
                <Typography fontSize={10}>
                  {t("pages.profile.managedBy")} Loyac.org
                </Typography>
                <Avatar
                  alt={name}
                  src={profileImage}
                  sx={{ width: 60, height: 60, marginY: "15px" }}
                />
                <Typography>{t("pages.profile.welcomeBack")}</Typography>
                <Box display={"flex"} marginY={"10px"}>
                  <Button
                    onClick={() => {
                      handleClose();
                      handleProfile();
                    }}
                    variant="outlined"
                  >
                    {t("pages.profile.manageProfile")}
                  </Button>
                </Box>
              </Box>
            </Box>
            {(is_applicant || is_parent) && (
              <MenuItem
                onClick={async () => {
                  const status = await fetchUserEmailStatus(email);
                  if (status) {
                    setsubscribtionStatus(status);
                    handleClose();
                  }
                  setSettingsLoading(false);
                  setSettingsModal(true);
                }}
              >
                {settingsLoading ? (
                  <CircularProgress size={30} />
                ) : (
                  <Settings />
                )}
                <Typography ml={3}>{t("auth.notificationSettings")}</Typography>
              </MenuItem>
            )}
            <Divider />
            <MenuItem onClick={handleAccountSettings}>
              <Settings />
              <Typography ml={3}>{t("common.settings")}</Typography>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => logoutButton()}>
              <ExitToApp color="error" />
              <Typography ml={3} color={"red"}>
                {t("common.logout")}
              </Typography>
            </MenuItem>
          </div>
        )}
      </MuiMenu>
      <UserSettingsModal
        setsubscribtionStatus={setsubscribtionStatus}
        subscribtionStatus={subscribtionStatus}
        open={settingsModal}
        handleClose={() => {
          setSettingsModal(false);
          // @ts-ignore
          setsubscribtionStatus(null);
        }}
      />
    </>
  );
};

export default Logout;
